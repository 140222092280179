/* src/app/pages/login/app.login.component.scss */
@media screen and (min-width: 700px) {
  .margin-top-custom {
    margin-top: 0% !important;
  }
}
@media screen and (min-width: 1000px) {
  .margin-top-custom {
    margin-top: 1% !important;
  }
}
@media screen and (min-width: 1400px) {
  .margin-top-custom {
    margin-top: 3% !important;
  }
}
@media screen and (min-width: 1800px) {
  .margin-top-custom {
    margin-top: 25% !important;
  }
}
/*# sourceMappingURL=app.login.component-QAFRTVUA.css.map */
