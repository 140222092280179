import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy
} from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
//ngrx
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

// Application Components
import { AppComponent } from "./app.component";
import { appReducer } from "./app.reducer";

import { AppAccessdeniedComponent } from "./pages/accessdenied/app.accessdenied.component";
import { DevComponent } from "./pages/dev/dev.component";
import { AppErrorComponent } from "./pages/error/app.error.component";
import { AppNotfoundComponent } from "./pages/notfound/app.notfound.component";

import { ConfirmationService, MessageService } from "primeng/api";
import { MenuService } from "./services/app.menu.service";
import { BreadcrumbService } from "./services/breadcrumb.service";

import { DialogService } from "primeng/dynamicdialog";
import { AppRoutingModule } from "./app.routes";
import { AppMainComponent } from "./components/main/app.main.component";
import { LoginModule } from "./pages/login/login.module";
// import { LoaderComponent } from "./pages/loader/loader.component";
import { registerLocaleData } from '@angular/common';
import localeCo from '@angular/common/locales/es-US';

registerLocaleData(localeCo, 'es-US');

@NgModule({ declarations: [
        // LoaderComponent,
        AppComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        DevComponent,
    ],
    // exports: [LoaderComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoginModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        //store
        StoreModule.forRoot(appReducer, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            // logOnly: environment.production, // Restrict extension to log-only mode
        })], providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LOCALE_ID, useValue: "es-US" },
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: ProgressInterceptorService,
        //   multi: true,
        // },
        BreadcrumbService,
        MenuService,
        MessageService,
        DialogService,
        AppMainComponent,
        ConfirmationService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
