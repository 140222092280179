<div class="exception-body notfound">
    <div class="exception-content">
        <div class="moon">
            <img src="assets/layout/images/pages/asset-moon.svg" alt="mirage-layout" />
        </div>
        <div class="exception-panel">
            <div class="exception-panel-content">
                <span class="tag">404</span>
                <h1>Página no encontrada</h1>
                <div class="seperator"></div>
                <p>El recurso solicitado no esta disponible. Por favor inténtelo mas tarde.</p>
                <button pButton type="button" [routerLink]="['/']" label="Volver a página de inicio"></button>
            </div>
        </div>
        <div class="desert"></div>
    </div>
</div>
