<div *ngIf="logging">
  <div class="splash-screen">
    <div class="spinner">
      <span class="ball-1"></span>
      <span class="ball-2"></span>
      <span class="ball-3"></span>
      <span class="ball-4"></span>
      <span class="ball-5"></span>
      <span class="ball-6"></span>
      <span class="ball-7"></span>
      <span class="ball-8"></span>
    </div>
  </div>
</div>
<div class="login-body layout-light" *ngIf="!logging" id="loginBody">
  <div class="login-content">
    <div class="login-panel">
      <div class="login-panel-content custom-container-login-form">
        <p style="color: #fff; font-size: 2rem;" *ngIf="loginText">
          <!-- <h1 style="color: #fff;" *ngIf="loginText">
          <strong>
            {{loginText}}
          </strong>
        </h1> -->
          <strong>
            {{loginText}}
          </strong>
        </p>
        <div class="logo">
          <img
            *ngIf="logoLogin"
            src="{{logoLogin}}"
            alt="CatasIA"
            [style.height]="logoLoginHeight"
            [style.width]="'auto'"
          />
        </div>
        <!-- <h1 style="color: #fff;"><strong>CatasIA</strong></h1> -->
        <p class="custom-color-text-welcome">
          Bienvenido, por favor use el formulario para autenticarse
        </p>
        <p-toast key="tst" [style]="{ marginTop: '70px' }"></p-toast>
        <div class="forms">
          <div class="login-input-wrapper">
            <label class="custom-color-text-input-login" for="username">Usuario</label>
            <input [disabled]="loading"
              id="username"
              pInputText
              placeholder="Escriba su nombre de usuario..."
              [(ngModel)]="user.email"
              (keyup.enter)="login()"
            />
            <i class="pi pi-user"></i>
          </div>

          <div class="login-input-wrapper">
            <label class="custom-color-text-input-login" for="password">Contraseña</label>
            <input [disabled]="loading"
              type="password"
              id="password"
              placeholder="Escriba su contraseña"
              pPassword
              [feedback]="false"
              [(ngModel)]="user.password"
              (keyup.enter)="login()"
            />
            <i class="pi pi-lock"></i>
          </div>

          <div class="col-12" *ngIf="false">
            <p-checkbox
              labelStyleClass="custom-color-text-remember-login"
              [(ngModel)]="rememberme"
              label="Recordarme"
              value="SaveInfo"
              binary="true"
            ></p-checkbox>
          </div>

          <div>
            <button
              class="custom-color-button-login py-2 px-4"
              pButton
              type="button"
              label="Ingresar"
              (click)="login()"
              [disabled]="loading"
            ></button>
            <p-progressSpinner *ngIf="loading" [style]="{ width: '28px', height: '28px', marginTop: '15px' }" strokeWidth="2">
            </p-progressSpinner>

            <!-- <a href="#" class="secondary-button" style="color: #fff;"
              >¿Olvidó su contraseña?</a
            > -->
          </div>
        </div>
        <div
          class="margin-top-custom"
          style="
            flex-wrap: nowrap;
            display: flex;
            justify-content: space-between;
          "
          *ngIf="logofooterEsri && logofooterCatasIA"
        >
          <div class="logo" style="width: min-content !important;">
            <img
              src="{{logofooterEsri}}"
              alt="CatasIA"
              [style.height]="logofooterEsriHeight"
              [style.width]="'auto'"
            />
          </div>
          <div class="logo" style="width: min-content !important;">
            <img
              src="{{logofooterCatasIA}}"
              alt="CatasIA"
              [style.height]="logofooterCatasIAHeight"
              [style.width]="'auto'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-loader *ngIf="loadingModules"></app-loader> -->
